import React, { createContext, useState, useCallback } from 'react';
import Modal from './Modal';
import { useModal } from './hooks/useModal';
import { useForm } from 'react-hook-form';
import API from 'utils/api.js';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import useAuth from './hooks/useAuth';
import FullNameAvatar from 'components/Avatar/FullNameAvatar';
import Alert from 'components/Alert/Alert';
import { Button } from 'components/button';

const ReportContext = createContext({});

export const ReportProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const { openModal, closeModal } = useModal();

  const openReport = useCallback((contentId, type) => {
    openModal(
      <div className="m-6">
        <ReportForm contentId={contentId} type={type} />
      </div>,
      ''
    );
  }, []);

  const closeReport = useCallback(() => {}, []);

  return (
    <ReportContext.Provider
      value={{ modalOpen, modalTitle, openReport, closeReport }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export default ReportContext;

function ReportForm({ contentId, type }) {
  const { auth } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusError, setStatusError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async (data) => {
    await addFeedback({
      name: data.name,
      email: data.email,
      message: data.message,
      problem: data.problem,
      contentId: contentId,
    });
  };

  const addFeedback = ({name, email, message, problem, contentId}) => {
    if (!auth.user) {
      if (!name || !email || !message) {
        setStatusError('Please fill out all fields');
        return;
      }
    }

    API.post('/api/feedback/add', {
      email: email,
      name: name,
      message: message,
      reporter: auth.user?._id ? auth.user._id : null,
      type: type,
      problem: problem,
      contentId: contentId,
    })
      .then((res) => {
        console.log(res);
        setStatusMessage(res.data.msg);
        setIsSubmitted(true);
      })
      .catch((err) => {
        console.log(err);
        setStatusError('Something went wrong, please try again');
      });
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
        <div className="flex-auto">
          <div className="text-center mb-6">
            <h3 className="text-3xl font-semibold">Report {type.substring(0, 1).toUpperCase() + type.substring(1)}</h3>
            <div className="relative w-full mb-3 text-gray-500 text-xs">
              {type.substring(0, 1).toUpperCase() + type.substring(1)}-Id: {contentId}
            </div>
          </div>
          {!isSubmitted && (
            <>
              {auth.user ? (
                <div className="relative w-full mb-3">
                  <label className="block mb-2 text-sm font-medium text-gray-900 ">
                    Reporter:
                  </label>
                  <FullNameAvatar isLink={false} author={auth.user} sizeClass="w-12 h-12" />
                </div>
              ) : (
                <>
                  <div className="relative w-full mb-3">
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">
                      Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      {...register('name', {
                        required: 'required',
                      })}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-700 focus:border-purple-700 block w-full p-2.5"
                      placeholder="Name"
                    />
                    {errors.name && (
                      <p className="mt-2 text-sm text-red-600 ">
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                  <div className="relative w-full mb-3">
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      {...register('email', {
                        required: 'required',
                        pattern: {
                          value:
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/,
                          message: 'Please enter a valid email address',
                        },
                      })}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5      "
                      placeholder="your-email@mail.com"
                    />
                    {errors.email && (
                      <p className="mt-2 text-sm text-red-600 ">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                </>
              )}
              <div className="relative w-full mb-3">
                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                  Problem <span className="text-red-500">*</span>
                </label>
                <select
                  {...register('problem', { required: true })}
                  defaultValue=""
                  className="bg-gray-50 border border-gray-300 focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm rounded-md"
                >
                  <option value="" disabled>
                    Select type of Problem
                  </option>
                  <option value="spam">Spam</option>
                  <option value="harrasment">Harrasment or Hate Speech</option>
                  <option value="rude">Rude or Vulgar</option>
                  <option value="copyright">Copyright infringement</option>
                  <option value="guidelines">Violates Content guidelines</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="relative w-full mb-3">
                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                  Message <span className="text-red-500">*</span>
                  <div className="text-xs text-gray-500 font-light">
                    Share additional details to help us manage the situation.
                  </div>
                </label>
                <textarea
                  {...register('message', {
                    required: 'required',
                  })}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-700 focus:border-purple-700 block w-full p-2.5"
                  placeholder="message"
                  rows="5"
                ></textarea>
                {errors.message && (
                  <p className="mt-2 text-sm text-red-600 ">
                    {errors.message.message}
                  </p>
                )}
              </div>
              <Alert text="False reports can lead to account suspension." />
              <div className="text-center mt-6">
                <Button
                  color="quizini"
                  onClick={handleSubmit(onSubmit)}
                  className="w-full cursor-pointer"
                >
                  Submit
                </Button>
              </div>
            </>
          )}
          {statusMessage && (
            <div
              className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
              role="alert"
            >
              {statusMessage}
            </div>
          )}
          {statusError && (
            <p className="mt-2 text-sm text-red-600 ">{statusError}</p>
          )}
        </div>
      </div>
    </>
  );
}
