import UserDisplay from "components/User/UserDisplay";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import useAuth from "utils/hooks/useAuth";

export default function SimilarUsers() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  // const [similarUsers, setSimilarUsers] = useState([]); // [User

  const fetchSimilarUsers = async () => {
    const response = await axiosPrivate.post('/api/users/similar', {
      interests: auth?.user?.interests,
    });
    if (response.data?.success) {
      return response.data.users;
    }
    throw new Error('Failed to get similar users');
  };
  
  const { data: similarUsers, isLoading, isError, error } = useQuery({
    queryKey: ['similarUsers', auth?.user?.interests],
    queryFn: fetchSimilarUsers,
    enabled: !!auth?.user,
    cacheTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60,
  });

  if (isLoading) {
    return <div>Loading similar users...</div>;
  }

  if (isError) {
    return <div>Error fetching similar users: {error.message}</div>;
  }
  
  return (
    <div className="">
      {similarUsers.map((user, index) => (
        <div className="mb-2 hover:bg-gray-100 rounded-lg" key={user.id || index}>
          <UserDisplay user={user} type="username" />
        </div>
      ))}
    </div>
  );
}
