import clsx from 'clsx';

export function Heading({ className, level = 1, size = 'md', ...props }) {
  let Element = `h${level}`;

  const sizeClasses = {
    sm: 'text-sm font-medium',
    md: 'text-2xl/8 font-semibold sm:text-xl/8',
    lg: 'text-4xl font-bold',
  };

  return (
    <Element
      {...props}
      className={clsx(className, sizeClasses[size], 'text-zinc-950 dark:text-white')}
    />
  );
}

export function Subheading({ className, level = 2, ...props }) {
  let Element = `h${level}`;

  return (
    <Element
      {...props}
      className={clsx(className, 'text-base/7 font-semibold text-zinc-950 sm:text-sm/6 dark:text-white')}
    />
  );
}
